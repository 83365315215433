<template>
  <div class="flex flex-col my-10">
    <!-- Header : Webhook -->
    <div class="mx-3 mb-2 flex flex-col md:flex-row justify-between">
      <p class="font-medium">
        {{ $t("integration.menu.webhook") }}
      </p>
      <div class="flex flex-inline space-x-2">
        <!-- Integration Docs  -->
        <app-button
          width="w-auto"
          :primary="false"
          :showf70Icon="false"
          @click="openApiDoc"
        >
          <template v-slot:icon>
            <app-icon-outline
              name="BookOpenIcon"
              class="h-4 w-4 text-black mr-2"
            />
          </template>
          {{ $t("integration.api.api_docs") }}
        </app-button>

        <!-- add new webhook -->
        <app-button
          width="w-auto"
          :showf70Icon="false"
          :disabled="integration.signature_key == null || apiPaginationLoading"
          @click="openModalWebhook"
        >
          <template v-slot:icon>
            <app-icon-outline name="PlusIcon" class="h-4 w-4 text-white mr-2" />
          </template>
          {{ $t("integration.webhook.new") }}
        </app-button>
      </div>
    </div>
    <hr />

    <!-- Signature Key -->
    <div class="flex flex-col mx-6 mt-6">
      <app-form-select-master-business-list-business
        :includeMasterBusiness="true"
        v-if="hasRoleMasterBusiness"
        v-model="selectBusiness"
        class="w-full"
        :loading="apiPaginationLoading"
      />
      <div
        class="
          flex flex-col
          md:flex-row md:space-y-0
          space-y-5 space-x-4
          items-end
          my-6
        "
      >
        <!-- Form Signature key  -->
        <div class="flex-auto relative w-full">
          <app-form-input
            type="text"
            class="w-full"
            :disabled="true"
            :labelText="$t('integration.webhook.key')"
            v-model="integration.signature_key"
          />
          <button
            class="absolute top-9 right-1"
            @click="copySignatureKey(integration.signature_key)"
            :disabled="integration.signature_key == null"
          >
            <app-icon name="ClipboardCopyIcon" class="h-6 w-6 text-gray-400" />
          </button>
        </div>

        <!-- Reset Signature key  -->
        <app-button
          class="flex-none"
          width="md:w-auto w-full"
          :showf70Icon="false"
          :loading="apiPaginationLoading"
          :disabled="integration.signature_key == null"
          @click="showResetApiDialog()"
        >
          <p class="">
            {{ $t("integration.webhook.reset_button") }}
          </p>
        </app-button>
      </div>

      <!-- Webhook List -->
      <app-table
        :apiResponse="apiPaginationResponse.data"
        :showEmptyButton="integration.signature_key"
        @on-item-click="onItemClick"
        :loading="apiPaginationLoading"
        :filters="[
          {
            key: 'filter[name]',
            type: 'text',
            placeholder: $t('integration.webhook.form.event_name'),
            value: '',
          },
          {
            key: 'filter[url]',
            type: 'text',
            placeholder: $t('integration.webhook.form.url'),
            value: '',
          },
        ]"
        @params-changed="paramsChanged"
        @empty-button-clicked="openModalWebhook"
      >
        <template v-slot:header>
          <th>Event Name</th>
          <th>Url</th>
          <th>Enable</th>
          <th>Action</th>
        </template>
        <template v-slot:body="slotData">
          <td>
            <p
              class="
                py-1.5
                px-2
                text-xs
                lowercase
                rounded-md
                text-center
                bg-light-badge
                hover:bg-gray-200
              "
            >
              {{ slotData.model.name }}
            </p>
          </td>
          <td>
            <p>{{ slotData.model.url }}</p>
          </td>
          <td @click.stop="">
            <p>
              {{
                slotData.model.enabled ? $t("general.yes") : $t("general.no")
              }}
            </p>
          </td>
          <td @click.stop="">
            <div
              @click="editWebhook(slotData.model)"
              class="
                flex flex-row
                justify-center
                bg-background
                px-2
                py-2
                w-10
                rounded-md
                border-2
                hover:bg-bordercolor
              "
            >
              <button>
                <app-icon-outline
                  name="PencilAltIcon"
                  class="h-5 w-5 text-gray-400"
                />
              </button>
            </div>
          </td>
        </template>
      </app-table>

      <!-- Footer : Note API Document  -->
      <div class="flex flex-wrap mt-9 text-sm mb-2">
        <span>
          {{ $t("integration.api.notes_1") }}
        </span> 
        <a href="#" class="mx-1" @click="openApiDoc">
           {{ $t("integration.api.api_docs") }} 
        </a> 
        <span>
          {{ $t("integration.api.notes_2") }}
        </span>
      </div>
    </div>

    <!-- Confirm Modal -->
    <app-modal :show="showModalConfirm" @close="showModalConfirm = false">
      <template v-slot:title>{{
        $t("integration.webhook.reset_prompt_title")
      }}</template>
      <template v-slot:body>
        <p class="text-sm text-gray-500">
          {{ $t("integration.webhook.reset_prompt") }}
        </p>
      </template>
      <template v-slot:action>
        <app-button
          @onClick="resetSignatureKey()"
          @click="showModalConfirm = false"
          :showf70Icon="false"
          :primary="false"
          type="button"
          class="
            sm:ml-3 sm:w-auto
            bg-approve
            hover:bg-success
            text-white
            outline-none
          "
        >
          {{ $t("general.proceed") }}
        </app-button>
      </template>
    </app-modal>

    <!-- Add Webhook Modal -->
    <app-modal :show="showWebhookModal" @close="closeModalWebhook">
      <template v-slot:body>
        <form @submit.prevent="createUpdateWebhook()">
          <div class="flex flex-col items-start space-y-2 pr-5">
            <app-form-select
              class="w-full"
              :datas="eventNames"
              :labelText="$t('integration.webhook.form.event_name')"
              :errorMessage="errors.name"
              :loading="loadingEventNames"
              :allowFilter="false"
              :showLabel="true"
              v-model="webhook.name"
              v-on:retry="getEventNames"
              :disabled="webhook._id != null"
            />
            <app-form-input
              type="text"
              class="w-full"
              :labelText="$t('integration.webhook.form.url')"
              :placeholder="$t('general.url_placeholder')"
              v-model="webhook.url"
              :errorMessage="errors.url"
            />
            <app-form-checkbox
              v-model="webhook.enabled"
              :errorMessage="errors.enabled"
            >
              {{ $t("integration.webhook.form.enable") }}
            </app-form-checkbox>
          </div>
        </form>
      </template>
      <template v-slot:action>
        <app-button
          @onClick="createUpdateWebhook()"
          type="button"
          :showf70Icon="false"
          class="sm:ml-2 sm:w-44 text-center"
        >
          {{
            webhook._id != null
              ? $t("general.save")
              : $t("integration.webhook.new")
          }}
        </app-button>
      </template>
    </app-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showModalConfirm: false,
      showWebhookModal: false,
      selectWebhook: null,
      queryParams: "",
      emptyErrors: {
        name: [],
        url: [],
        enabled: [],
      },
    };
  },

  watch: {
    selectBusiness(business) {
      if (business.id) {
        this.$store.dispatch(
          "integrationStore/retrieveIntegration",
          business.id
        );
      }
    },
    integration(integration) {
      if (integration.id) {
        this.fetchWebhookIndex();
        this.getEventNames();
      }
    },
  },

  async mounted() {
    await this.fetchWebhookIndex();
    await this.getEventNames();
  },

  computed: {
    selectBusiness() {
      return this.$store.getters["masterBusinessStore/selectBusiness"];
    },

    hasRoleMasterBusiness() {
      return this.$store.getters["authStore/hasRoleMasterBusiness"]();
    },

    apiPaginationResponse() {
      return this.$store.getters["integrationStore/apiPaginationResponse"];
    },

    integration() {
      return this.$store.getters["integrationStore/integration"];
    },

    apiPaginationLoading() {
      return this.$store.getters["integrationStore/apiPaginationLoading"];
    },

    webhook() {
      return this.$store.getters["integrationStore/webhook"];
    },

    eventNames() {
      return this.$store.getters["integrationStore/eventNames"];
    },
    
    isMasterBusinessRole() {
      return this.$store.getters["authStore/isMasterBusinessRole"]
    },

    loadingEventNames() {
      return this.$store.getters["integrationStore/loadingEventNames"];
    },

    errors() {
      return this.$store.getters["integrationStore/errors"];
    },

    message() {
      return this.$store.getters["integrationStore/message"];
    },
  },

  methods: {
    copySignatureKey(key) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(key);

      this.$notify(
        {
          group: "success",
          title: this.$t("integration.webhook.copy"),
        },
        5000
      );
    },

    showResetApiDialog() {
      this.showModalConfirm = true;
    },

    resetSignatureKey() {
      this.$store.dispatch(
        "integrationStore/resetSignatureKey",
        this.selectBusiness?.id
      );
    },

    openApiDoc() {
      window.open(this.$store.$backendURL + "/docs", "_blank");
    },

    async fetchWebhookIndex() {
      await this.$store.dispatch("integrationStore/webhookIndex", {
        business_id: this.selectBusiness?.id,
        is_master_business: this.isMasterBusinessRole,
        integration_id: this.integration?.id,
        queryParams: this.queryParams
      });
    },

    openModalWebhook() {
      this.$store.commit("integrationStore/setWebhook", {});
      this.$store.commit("integrationStore/setErrorMessage");
      this.showWebhookModal = true;
    },

    closeModalWebhook() {
      this.showWebhookModal = false;
      this.$store.commit("integrationStore/setWebhook", {});
      this.$store.commit("integrationStore/setErrorMessage");
    },

    editWebhook(webhook) {
      const eventName = this.eventNames.find(
        (eventName) => eventName.name === webhook.name
      );
      const toEditWebhook = Object.assign({}, webhook);
      toEditWebhook.name = eventName;
      toEditWebhook.enabled = toEditWebhook.enabled == 1;

      this.$store.commit("integrationStore/setWebhook", toEditWebhook);
      this.showWebhookModal = true;
    },

    getEventNames() {
      this.$store.dispatch("integrationStore/retrieveEventNames", this.isMasterBusinessRole);
    },

    onItemClick(webhookEvent) {
      this.$store.commit("integrationStore/setWebhook", webhookEvent);
      this.$router.push({
        name: "integration-webhook-details",
        params: {
          business_id: webhookEvent.integration.business_id,
          integration_id: webhookEvent.integration.id,
          webhook_event_id: webhookEvent._id,
        },
      });
    },

    async createUpdateWebhook() {
      this.resetState();

      const webhookResult = await this.$store.dispatch(
        "integrationStore/createOrUpdateWebhook",
        this.selectBusiness?.id
      );

      if (webhookResult == null) return;

      this.showWebhookModal = false;
      this.fetchWebhookIndex()
      this.getEventNames();
    },

    paramsChanged(filters, queryParams) {
      this.queryParams = queryParams;

      if (this.selectBusiness?.id) {
        this.queryParams = (this.queryParams == "") ? "?business_id=" + this.selectBusiness.id :
          this.queryParams + "&business_id=" + this.selectBusiness.id;
      }

      this.fetchWebhookIndex()
      this.getEventNames();
    },

    resetState() {
      this.$store.commit("integrationStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>